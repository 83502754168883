module.exports = {
  page: {
    logo: "/img/logo_gold_dunkelgrau.svg",
    icon: "/img/icon_griese.png",
    backendBaseUrl:
      window.location.hostname.indexOf(".loc") >= 2
        ? "http://backend.griese.loc/v1"
        : "https://backend.sg-fine-wine.com/v1",
    apiBaseUrl:
      window.location.hostname.indexOf(".loc") >= 2
        ? "http://api.griese.loc/v1"
        : "https://api.sg-fine-wine.com/v1",
    imageBaseUrl:
      window.location.hostname.indexOf(".loc") >= 2
        ? "http://api.griese.loc"
        : "https://api.sg-fine-wine.com"
  },
  stock: {
    racks: 16,
    traysPerRack: 1
  },
  owner: {
    company: "SG Fine Wine",
    address: "Hasenpohler Weg 27",
    zip: "53639",
    city: "Königswinter"
  },
  development: {
    supportEmail: "support@haeger.de",
    supportTel: "+494821439940",
    company: "haeger new media",
    version: "2.0.26" 
  },
  vat: 19,
  urlsWithoutLogin: [
    "/login",
    "/login/password",
    "/login/imprint",
    "/login/npw/*"
  ]
}