<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">#{{ data.id }} {{ data.name_de }}</h1>
    </div>
    <div class="card-content">
      <div class="columns view-columns is-multiline">
        <div class="column is-3">
          <label>Wein-ID</label>
          #{{ $utilities.padLeftWithNull(data.id) }}
        </div>
        <div class="column is-3">
          <label>Region</label>
          {{ $parent.$parent.getRegion(data.region_id) }}
        </div>
        <div class="column is-3">
          <label>Produzent</label>
          {{ $parent.$parent.getProducer(data.producer_id) }}
        </div>
        <div class="column is-3">
          <label>Typ</label>
          {{ $parent.$parent.getTyp(data.typ) }}
        </div>
        <div class="column is-6">
          <label>Name deutsch</label>
          {{ data.name_de }}
        </div>
        <div class="column is-6">
          <label>Name englisch</label>
          {{ data.name_en }}
        </div>

        <div class="column is-6">
          <label>Jahrgang</label>
          {{ data.jahr == 0 ? "n.V." : data.jahr }}
        </div>

        <div class="column is-3">
          <label>EAN</label>
          {{ data.ean }}&nbsp;
        </div>

        <div class="column is-3">
          <label>Livex Color</label>
          {{ data.l_color }}&nbsp;
        </div>

        <div class="column is-3">
          <label>Livex Typ</label>
          {{ data.typ }}&nbsp;
        </div>

        <div class="column is-3">
          <label>Livex SubType</label>
          {{ data.subtyp }}&nbsp;
        </div>

        <div class="column is-3">
          <label>Livex Designation</label>
          {{ data.designation }}&nbsp;
        </div>

        <div class="column is-3">
          <label>Livex Classif.</label>
          {{ data.classification }}&nbsp;
        </div>

        <div class="column is-12">
        <label>Standardbild</label>
        <img :src="getImage(data.image)" style="max-height: 200px;" alt="" />
        </div>





<div class="column is-3">
            <label>Nährwerte Brennwert in KJ</label>
             {{ data.nut_brennwert }}&nbsp;KJ
          </div>

          <div class="column is-3">
            <label>Nährwerte Alkohol in %</label>
             {{ data.nut_alkohol }}&nbsp;g
          </div>
          <div class="column is-3">
            <label>Nährwerte Kohlenhydrate in g</label>
             {{ data.nut_kohlenhydrate }}&nbsp;g
          </div>
          <div class="column is-3">
            <label>Nährwerte Zucker in g</label>
             {{ data.nut_zucker }}&nbsp;g
          </div>
          <div class="column is-6">
            <label>Nährwerte Liste Inhaltsstoffe deutsch</label>
             {{ data.nut_inhaltsstoffe_de }}&nbsp;
          </div>
          
          <div class="column is-6">
            <label>Nährwerte Liste Inhaltsstoffe englisch</label>
             {{ data.nut_inhaltsstoffe_en }}&nbsp;
          </div>
          <div class="column is-6">
            <label>Nährwerte Liste Allergene deutsch</label>
               {{ data.nut_allergene_de }}&nbsp;
          </div>
          <div class="column is-6">
          <label>Nährwerte Liste Allergene englisch</label>
            {{ data.nut_allergene_en }}&nbsp;
          </div>
          <div class="column is-12">
          <label>Nährwerte externer Link</label>
          
             <a :href="data.nut_link" target="_blank">{{ data.nut_link }}</a>&nbsp;
          </div>






      </div>

      <b-button
        class="button"
        type="is-info"
        @click="edit(data.id)"
        icon-left="square-edit-outline"
        >Bearbeiten</b-button
      >

      &nbsp;

      <b-button
        class="button"
        type="is-danger"
        icon-left="delete_forever"
        @click="delete1(data.id, data.name_de)"
        >Löschen</b-button
      >
    </div>
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">
          Schließen
        </button>
        &nbsp;
      </div>
    </div>
    <div class="p-3">
      <h3 class="title is-4 mb-0">Wein-Bewertungen</h3>

      <a @click="ratingadd()" type="is-link" class="navbar-link is-arrowless"
        ><b-icon icon="plus1"></b-icon
      ></a>

      <p v-if="typeof ratings == 'undefined' || ratings.length == 0">
        Es liegen noch keine Bewertungen vor
      </p>

      <div
        class="table-wrapper"
        v-if="typeof ratings != 'undefined' && ratings.length >= 1"
      >
        <table class="table is-striped is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>ID</th>
              <th>Wert</th>
              <th>Bewertung</th>
              <th>A</th>
              <th>L</th>
            </tr>
          </thead>
          <tbody>
            <winerating
              v-for="(rating, index) in ratings"
              :key="`rating` + index"
              :rating="rating"
              :index="index"
              :inputmode="typeof rating.id == 'undefined' ? true : false"
              :ref="`rating` + index"
            ></winerating>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Winerating from "../_helpers/winerating.vue"
export default {
  name: "winesView",
  components: { Winerating },
  props: ["data"],
  data() {
    return {
      apiControllerName: "wines",
      loading: {},
      ratings: [],
      isLoading: false
    }
  },
  created: function () {
    this.ratings = this.data.ratings
  },
  methods: {
    delete1: function (id, name) {
      this.$parent.close()
      this.$parent.$parent.delete1(id, name)
    },
    edit: function (id) {
      this.$parent.close()
      this.$parent.$parent.edit(id)
    },
    ratingedit: function (index) {
      //console.log("Index " + index)
      //console.log(this.$refs["rating" + index])
      //this.$refs["rating" + index][0].changeInputmode()
      this.$refs["rating" + index][0].inputmode2 = true
    },

    ratingadd: function () {
      var t = this
      t.ratings.unshift({
        bewerter_id: 1,
        wine_id: t.data.id,
        wert: 0,
        wert_max: "",
        beschreibung: "",
        quelle: ""
      })
    },

    ratingdelete1: function (id, name) {
      var t = this
      t.modal = this.$buefy.dialog.confirm({
        title: "Bitte bestätigen!",
        message:
          "Wollen Sie Datensatz #" + id + ' "' + name + '" wirklich löschen?',
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.ratingdelete2(id)
      })
    },
    ratingdelete2: function (id) {
      //console.log('delete '+id);
      var t = this
      this.$http({
        url: "/wineratings/delete/" + id,
        method: "get"
      })
        .then(function (resp) {
          t.$parent.$parent.view(t.data.id)
          t.$parent.close()
          t.$parent.$parent.list()
          return true
        })
        .catch(function (error) {
          //console.log('Shithappens')
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            this.$buefy.toast.open({
              message: error.response.data.message,
              type: "is-danger",
              duration: 5000
            })
          } else {
            this.$buefy.toast.open({
              message:
                "Server (API) nicht erreichbar. Bitte versuchen Sie es später erneut.",
              type: "is-danger",
              duration: 5000
            })
          }
        })
    },
    async ratingsave(formdata) {
      //console.log('speichern3')
      this.isLoading = true
      var t = this
      t.$http
        .post("/wineratings/save", { data: formdata })
        .then(function (resp) {
          t.isLoading = false
          t.$parent.$parent.view(t.data.id)
          t.$parent.close()
          t.$parent.$parent.list()
          return true
        })
        .catch(function (error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
          return false
        })
      return false
    },
     getImage(image) {
      if (image != false) {
        return this.$store.getters.config.page.imageBaseUrl+"/" + image;
      } else {
        return this.$store.getters.config.page.imageBaseUrl+"/images/article_dummy.jpg";
      }
    },
  }
}
</script>
