var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.typeOfAction))])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Versandregion-ID"}},[_vm._v(" #"+_vm._s(_vm.$utilities.padLeftWithNull(_vm.data.id))+" ")])],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Name Ländergruppe (deutsch)","type":{ 'is-danger': _vm.$v.data.name_de.$error },"message":{
              'Bitte geben Sie einen deutschen Namen an.':
                _vm.submitted && _vm.$v.data.name_de.$error,
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.name_de.$error },model:{value:(_vm.$v.data.name_de.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.name_de, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.name_de.$model"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Name Ländergruppe (englisch)","type":{ 'is-danger': _vm.$v.data.name_en.$error },"message":{
              'Bitte geben Sie einen englischen Namen an.':
                _vm.submitted && _vm.$v.data.name_en.$error,
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.name_en.$error },model:{value:(_vm.$v.data.name_en.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.name_en, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.name_en.$model"}})],1)],1)]),_vm._l((_vm.data.weightrules),function(r,index){return _c('shipping-rule',{key:index,staticClass:"panel-block",attrs:{"id":'rule' + r.key,"r":r,"inputmode":true,"selectWeightOptions":_vm.selectWeightOptions},on:{"rule-delete":_vm.ruleDelete}})}),_c('div',[_c('button',{staticClass:"button",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addShippingRule()}}},[_vm._v(" Gewichtsregel hinzufügen ")])])],2),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"column"},[_c('b-button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v("Schließen")]),_vm._v("   "),_c('b-button',{staticClass:"button",attrs:{"type":"is-info","native-type":"submit","icon-left":"cloud_upload"}},[_vm._v("Speichern")])],1)])]),_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isLoading,"can-cancel":false},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }