<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">#{{ data.id }}  {{ data.name_de }}</h1>
    </div>
    <div class="card-content">
      <div class="columns view-columns">
        <div class="column ">
          <label>Versandregion-ID</label>
          #{{ $utilities.padLeftWithNull(data.id) }}
        </div>
        <div class="column ">
          <label>Name Ländergruppe (deutsch)</label>
          {{ data.name_de }}
        </div>
         <div class="column ">
          <label>Name Ländergruppe (english)</label>
          {{ data.name_en }}
        </div>
        
      </div>

      <div class="columns view-columns">
        <div class="column ">
          <label>Regeln</label>
          <div v-for= "(r, i) in data.weightrules" :key="i">
          bis {{ r.weight }} kg: {{ r.price }} €
          </div>
        </div>
       
      </div>

      <div class="columns view-columns">
        <div class="column ">
          <label>Zugeordnete Länder</label>
           <div v-for= "(r, i) in data.countries" :key="i">
           {{ r.name_de }} 
          </div>
        </div>
        
      </div>

      <b-button
        class="button"
        type="is-info"
        @click="edit(data.id)"
        icon-left="square-edit-outline"
        >Bearbeiten</b-button
      >

      &nbsp;

      <b-button
        class="button"
        type="is-danger"
        icon-left="delete_forever"
        @click="
          delete1(
            data.id,
            data.name_de 
          )
        "
        >Löschen</b-button
      >
    </div>
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">
          Schließen
        </button>
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "shippingRegionView",
  props: ["data"],

  methods: {
    delete1: function(id, name) {
      this.$parent.close()
      this.$parent.$parent.delete1(id, name)
    },
    edit: function(id) {
      this.$parent.close()
      this.$parent.$parent.edit(id)
    }
  }
}
</script>
